import React from 'react';
import ReactDOM from 'react-dom';

import { FormSection, FormSectionProps } from '../FormSection/FormSection.component';
import { ReactComponent as Cross } from './Cross.svg';
import styles from './Popup.module.css';

type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
} & FormSectionProps;

export function Popup({ isOpen, onClose, children, ...sectionProps }: PopupProps) {
  function handleClose() {
    onClose();
  }

  const portalContainer = document.getElementById('root');

  if (!isOpen || !portalContainer) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.popup} role="dialog">
      <div className={styles.overlay} onClick={handleClose} />
      <FormSection {...sectionProps}>
        <button className={styles.closeBtn} onClick={handleClose}>
          <Cross />
        </button>
        {children}
      </FormSection>
    </div>,
    portalContainer
  );
}
