import { IllegalButton } from '../IllegalButton/IllegalButton.component';
import styles from './FormStateSwitch.module.css';

export type SwitchStates = 'demo' | 'file-upload';

export type FormStateSwitchProps = {
  state: SwitchStates;
  onUpdateState: (state: SwitchStates) => void;
};

export function FormStateSwitch({ state, onUpdateState }: FormStateSwitchProps) {
  const handleDemo: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onUpdateState('demo');
  };

  const handleFile: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onUpdateState('file-upload');
  };

  return (
    <section className={styles.switch}>
      <IllegalButton
        type="button"
        variant={state === 'demo' ? 'contained' : 'outlined'}
        onClick={handleDemo}
      >
        Try a demo
      </IllegalButton>
      <IllegalButton
        type="button"
        variant={state === 'file-upload' ? 'contained' : 'outlined'}
        onClick={handleFile}
      >
        Upload a file
      </IllegalButton>
    </section>
  );
}
