import clsx from 'clsx';
import React from 'react';

import styles from './IllegalButton.module.css';

export type IllegalButtonProps = {
  secondary?: boolean;
  size?: 'smol' | 'big-boye';
  variant?: 'contained' | 'outlined';
  state?: 'inactive' | 'active';
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const IllegalButton = ({
  className,
  secondary,
  size = 'big-boye',
  variant = 'contained',
  ...buttonProps
}: IllegalButtonProps) => {
  return (
    <button
      {...buttonProps}
      className={clsx(styles.button, className, {
        [styles.secondary]: secondary,
        [styles.smol]: size === 'smol',
        [styles.outlined]: variant === 'outlined',
      })}
    />
  );
};
