import { useState } from 'react';

import { FlowDiagram } from '../FlowDiagram/FlowDiagram.component';
import { Popup } from '../Popup/Popup.component';
import styles from './Header.module.css';
import { ReactComponent as Legalize } from './Legalize.svg';
import hands from './the-creation-of-ai.png';

export function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <header className={styles.header}>
      <img className={styles.backgroundImage} src={hands} alt="LegalAIze" />
      <Legalize />
      <div className={styles.container}>
        <h1>
          Extract <strong>the most</strong>
          <br />
          important data in
          <br />
          seconds with AI
        </h1>
        <p>
          With AI we can save you a lot of time reading and searching through long agreements, NDAs
          etc. With a few clicks, you will get all the necessary information right where you wanted.{' '}
          <a href="" onClick={handleLinkClick}>
            You can check what we do here.
          </a>
        </p>
      </div>
      <Popup
        isOpen={isOpen}
        onClose={closePopup}
        header="What we do"
        subheader="Your personal data is secure and is removed before document upload"
      >
        <FlowDiagram />
      </Popup>
      <p className={styles.mobileMessage}>
        Currently, the functionality of the page is limited to desktop devices only.
      </p>
    </header>
  );
}
