import { ReactComponent as Fb } from './fb.svg';
import styles from './Footer.module.css';
import { ReactComponent as Idego } from './Idego.svg';
import { ReactComponent as Insta } from './insta.svg';
import { ReactComponent as Li } from './li.svg';
import { ReactComponent as Website } from './website.svg';

const LINKS = [
  { alt: 'Idego Group', comp: <Website />, to: 'https://idego-group.com/' },
  { alt: 'Idego Facebook', comp: <Fb />, to: 'https://www.facebook.com/idegogroup' },
  { alt: 'Idego Instagram', comp: <Insta />, to: 'https://www.instagram.com/idegogroup/' },
  { alt: 'Idego LinkedIn', comp: <Li />, to: 'https://www.linkedin.com/company/idego-group/' },
];

export function Footer() {
  return (
    <footer className={styles.footer}>
      <section className={styles.top}>
        <div className={styles.text}>
          <Idego />
          <h1>
            Interested in
            <wbr /> AI&nbsp;technology?
          </h1>
          <h2>Let&apos;s have a talk</h2>
        </div>
        <a
          href="https://idego-group.com/contact/"
          target="_blank"
          rel="noreferrer"
          className={styles.contactButton}
        >
          Contact us
        </a>
      </section>
      <section className={styles.bottom}>
        <div>Idego Group 2023</div>
        <div className={styles.spacer} />
        <a download="Responsible AI of LegalAIze" href="/responsible_ai.pdf">
          Responsible AI
        </a>
        <a download="Privacy Policy of LegalAIze" href="/privacy_policy.pdf">
          Privacy Policy
        </a>
        <div className={styles.divider} />
        {LINKS.map(({ alt, comp, to }) => (
          <a key={to} href={to} target="_blank" rel="noreferrer" title={alt}>
            {comp}
          </a>
        ))}
        <p>
          <strong>DISCLAIMER:</strong> This platform is provided as a service and for informational
          purposes only. This service does not constitute legal advice, nor does it establish any
          form of attorney-client relationship with our company. While every effort is made to
          ensure that the information provided by this platform is accurate, it may contain errors
          or omissions for which we disclaim any and all liability. All service features are subject
          to availability and may be changed at any time.
        </p>
      </section>
    </footer>
  );
}
