export const EXAMPLE_TEXT_TITLE = 'Try it out!';

export const EXAMPLE_TEXT_CONTENT = `The Master Services Agreement #2 is signed on
05 May 2021 between Google LLC (the "Supplier") and Microsoft Corp (the "Buyer"). The
Supplier shall pay the Buyer for the services provided hereunder 15.5 million SEK (fifteen
million five hundred thousand Swedish kronor). The agreement shall not be interpreted of
constructed to create an association, joint venture, agency relationship, or partnership
between the Parties. The agreement shall be in effect for a period of 1 (one) calendar
year or until the services hereunder have been provided and paid for, whichever occurs
first. The agreement shall be governed by and constructed in accordance with the substantive
laws of the Commonwealth of Massachusetts and the Parties consent to the jurisdiction of
courts, both state or federal, in Massachusetts, with respect to any dispute under the Agreement.`;

export const UPLOAD_FILE_TITLE = 'Upload your NDA and see what I can do!';

export const SELECT_FIELDS_TITLE = 'What should I look for?';

export const DEFAULT_RESPONSE_VALUE = 'Not specified';
