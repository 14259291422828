import React from 'react';

import styles from './Results.module.css';

export type ResultsData = Record<string, string>;

export type ResultsProps = {
  results: ResultsData;
};

export const Results: React.FC<ResultsProps> = ({ results }) => {
  return (
    <table className={styles.resultsTable}>
      <thead>
        <tr>
          <th className={styles.headerCell}>Data type</th>
          <th className={styles.headerCell}>Context from document</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(results).map(([key, value]) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
