import { Footer } from '../Footer/Footer.component';
import { Form } from '../Form/Form.component';
import { Header } from '../Header/Header.component';

export function Page() {
  return (
    <>
      <Header />
      <Form />
      <Footer />
    </>
  );
}
