import React from 'react';

import styles from './FlowDiagram.module.css';
import { ReactComponent as ArrowComponent } from './right-arrow.svg';

export function FlowDiagram() {
  return (
    <div className={styles.dotDiagram}>
      <div className={styles.dot} style={{ gridColumn: '1 / 2' }}>
        Analyze and remove personal data from the document
      </div>
      <ArrowComponent />
      <div className={styles.dot} style={{ gridColumn: '3 / 4' }}>
        AI-powered interpretation of personal-free data document
      </div>
      <ArrowComponent />
      <div className={styles.dot} style={{ gridColumn: '5 / 6' }}>
        Reshape results making them ready to use
      </div>
    </div>
  );
}
