import clsx from 'clsx';
import React from 'react';

import { Spinner } from '../Spinner/Spinner.component';
import styles from './FormSection.module.css';

export type FormSectionProps = {
  header: string;
  subheader: string;
  children: React.ReactNode;
  error?: boolean;
  scrollIntoView?: boolean;
  loading?: boolean;
};

export function FormSection({
  header,
  subheader,
  children,
  error,
  scrollIntoView = false,
  loading = false,
}: FormSectionProps) {
  const ref = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (ref.current && scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoView]);

  return (
    <section ref={ref} className={clsx(styles.section, { [styles.error]: error })}>
      {loading && <Spinner />}
      <h2>{header}</h2>
      <h3>{subheader}</h3>
      {children}
    </section>
  );
}
