import { ButtonsFlex } from '../ButtonsFlex/ButtonsFlex.component';
import { IllegalButton } from '../IllegalButton/IllegalButton.component';

export const DOCUMENT_TYPES = [
  { name: 'NDA', disabled: false },
  { name: 'Business Plan', disabled: true },
  { name: 'Financial State', disabled: true },
  { name: 'Employment contract', disabled: true },
  { name: 'Sales Agreement', disabled: true },
  { name: 'Will', disabled: true },
  { name: 'Invoice', disabled: true },
];

export const DEFAULT_DOCUMENT = 'NDA';

export type TypesSelectorProps = {
  selectedType: string;
};

export function TypesSelector({ selectedType }: TypesSelectorProps) {
  return (
    <ButtonsFlex>
      {DOCUMENT_TYPES.map(({ name, disabled }) => (
        <IllegalButton
          type="button"
          variant={name === selectedType ? 'contained' : 'outlined'}
          key={name}
          disabled={disabled}
          size="smol"
        >
          {name}
        </IllegalButton>
      ))}
    </ButtonsFlex>
  );
}
