import { ButtonsFlex } from '../ButtonsFlex/ButtonsFlex.component';
import { FieldButton } from './FieldButton.component';

export const FIELDS = [
  { name: 'Parties', value: 'parties' },
  { name: 'Parties roles', value: 'parties_roles' },
  { name: 'Contact period', value: 'contract_period' },
  { name: 'Monetary amount', value: 'monetary_amount' },
  { name: 'NDA signatures', value: 'nda_signatories' },
  { name: 'Governing law', value: 'governing_law' },
  { name: 'Data protection', value: 'data_protection' },
  { name: 'Validity', value: 'valid_nda' },
  { name: 'Expiration date', value: 'nda_expiration_date' },
  { name: 'No competition clause', value: 'no_competition_clause' },
  { name: 'Prohibition of employment', value: 'prohibition_of_employment' },
  { name: 'Penalty for employment', value: 'penalty_for_employment' },
  { name: 'Confidential info', value: 'confidential_info_return' },
  { name: 'Breaking demands penalty', value: 'breaking_demands_penalty' },
  { name: 'Confidential info pass', value: 'confidential_info_pass' },
  { name: 'Confidential info penalty', value: 'passing_confidential_info_penalty' },
];

export type FieldsSelectorProps = {
  selected: Array<string>;
  onToggleField: (field: string) => void;
};

export function FieldsSelector({ selected, onToggleField }: FieldsSelectorProps) {
  return (
    <ButtonsFlex>
      {FIELDS.map(({ name, value }) => (
        <FieldButton
          value={value}
          onToggleField={onToggleField}
          name={name}
          key={value}
          selected={selected.includes(value)}
        />
      ))}
    </ButtonsFlex>
  );
}
