import styles from './SkeletonLoading.module.css';

export const SkeletonLoading = () => {
  const width = `${30 + Math.random() * 30}%`;

  return (
    <div
      style={{
        width,
      }}
      className={styles.skeletonLoading}
    >
      <div className={styles.animation}></div>
    </div>
  );
};
