import { IllegalButton } from '../IllegalButton/IllegalButton.component';

export type FieldButtonProps = {
  value: string;
  name: string;
  onToggleField: (field: string) => void;
  selected: boolean;
};

export function FieldButton({ value, onToggleField, name, selected }: FieldButtonProps) {
  const handleClick = () => {
    onToggleField(value);
  };
  return (
    <IllegalButton
      type="button"
      variant={selected ? 'contained' : 'outlined'}
      size="smol"
      key={value}
      onClick={handleClick}
    >
      {name}
    </IllegalButton>
  );
}
