import { EXAMPLE_TEXT_CONTENT } from '../../utils/constants';
import styles from './TextContent.module.css';

export function TextContent() {
  return (
    <div className={styles.content}>
      <p>{EXAMPLE_TEXT_CONTENT}</p>
    </div>
  );
}
