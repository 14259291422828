import clsx from 'clsx';
import React, { ChangeEvent, DragEvent, useState } from 'react';

import { IllegalButton } from '../IllegalButton/IllegalButton.component';
import styles from './FileUploadControl.module.css';

type FileUploadControlProps = {
  onFileChange: (file: File | null) => void;
};

export const FileUploadControl: React.FC<FileUploadControlProps> = ({ onFileChange }) => {
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    setSelectedFileName(files[0].name);
    onFileChange(files[0]);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (!files || files.length === 0) {
      return;
    }
    setSelectedFileName(files[0].name);
    onFileChange(files[0]);
  };

  const handleClearClick = () => {
    setSelectedFileName(null);
    onFileChange(null);
  };

  const containerClassName = clsx(styles.container, {
    [styles.dragging]: isDragging,
  });

  if (selectedFileName) {
    return (
      <div
        className={containerClassName}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <p>
          Selected file: <strong>{selectedFileName}</strong>
        </p>
        {isDragging ? (
          <span>Drop file here to replace...</span>
        ) : (
          <IllegalButton size="smol" onClick={handleClearClick}>
            Clear
          </IllegalButton>
        )}
      </div>
    );
  }

  return (
    <div
      className={containerClassName}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <label className={styles.uploadButton}>
        <input type="file" onChange={handleFileInputChange} accept=".pdf,.doc,.docx,.txt" />
        {isDragging ? <span>Drop file here...</span> : <span>Browse files</span>}
      </label>
      <p>
        *.doc, *.docx, *.pdf, *.txt file up to 20MB
        <br />
        Drag and drop file here...
      </p>
    </div>
  );
};
