import clsx from 'clsx';
import React from 'react';

import { SkeletonLoading } from '../SkeletonLoading/SkeletonLoading.component';
import styles from './Results.module.css';

export type ResultsSkeletonProps = {
  fieldsCount: number;
};

export const ResultsSkeleton: React.FC<ResultsSkeletonProps> = ({ fieldsCount }) => {
  return (
    <table className={clsx(styles.resultsTable, styles.loading)}>
      <thead>
        <tr>
          <th className={styles.headerCell}>Data type</th>
          <th className={styles.headerCell}>Context from document</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: fieldsCount }).map((_e, index) => (
          <tr key={index}>
            <td>
              <SkeletonLoading />
            </td>
            <td>
              <SkeletonLoading />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
