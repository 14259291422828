import { useState } from 'react';

import { FIELDS, FieldsSelector } from '../FieldsSelector/FieldsSelector.component';
import { FileUploadControl } from '../FileUploadControl/FileUploadControl.component';
import { FormSection } from '../FormSection/FormSection.component';
import { FormStateSwitch, SwitchStates } from '../FormStateSwitch/FormStateSwitch.component';
import { IllegalButton } from '../IllegalButton/IllegalButton.component';
import { Results, ResultsData } from '../Results/Results.component';
import { ResultsSkeleton } from '../Results/Results.skeleton';
import { TextContent } from '../TextContent/TextContent.component';
import { DEFAULT_DOCUMENT, TypesSelector } from '../TypesSelector/TypesSelector.component';
import styles from './Form.module.css';

type FormState =
  | {
      state: 'demo';
      documentType: string;
      selectedFields: Array<string>;
    }
  | {
      state: 'file-upload';
      file: File | null;
      documentType: string;
      selectedFields: Array<string>;
    }
  | {
      state: 'loading';
      fieldsCount: number;
    }
  | {
      state: 'results';
      data: ResultsData;
    }
  | {
      state: 'error';
      message: string;
    };

type Response =
  | {
      data: ResultsData;
      message: undefined;
      error: undefined;
    }
  | {
      data: undefined;
      message: string;
      error: undefined;
    }
  | {
      data: undefined;
      message: undefined;
      error: string;
    };

function prepareRequest(formState: FormState) {
  if (formState.state === 'demo') {
    return fetch('/api/v1/demo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fields: formState.selectedFields }),
    });
  }

  if (formState.state === 'file-upload' && formState.file != null) {
    const formData = new FormData();

    formState.selectedFields.forEach((value) => formData.append('fields', value));

    formData.append('document', formState.file);

    return fetch('/api/v1/import', {
      method: 'POST',
      body: formData,
    });
  }

  throw Error(`Incompatible state ${formState.state}`);
}

export function Form() {
  const [formState, setFormState] = useState<FormState>({
    state: 'demo',
    documentType: DEFAULT_DOCUMENT,
    selectedFields: [],
    // state: 'loading',
    // fieldsCount: 10,
  });

  const handleSwitch = (switchState: SwitchStates) => {
    if (switchState === 'demo') {
      setFormState({
        state: 'demo',
        documentType: DEFAULT_DOCUMENT,
        selectedFields: [],
      });
    }

    if (switchState === 'file-upload') {
      setFormState({
        state: 'file-upload',
        file: null,
        documentType: DEFAULT_DOCUMENT,
        selectedFields: [],
      });
    }
  };

  const handleFileChange = (file: File | null) => {
    setFormState({
      state: 'file-upload',
      file,
      documentType: DEFAULT_DOCUMENT,
      selectedFields: [],
    });
  };

  const handleFieldChange = (fieldParameter: string) => {
    setFormState((prev) =>
      prev.state === 'file-upload' || prev.state === 'demo'
        ? {
            ...prev,
            selectedFields: prev.selectedFields.some((f) => f === fieldParameter)
              ? prev.selectedFields.filter((f) => f !== fieldParameter)
              : [...prev.selectedFields, fieldParameter],
          }
        : prev
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (formState.state === 'file-upload' && formState.file == null) {
      return;
    }

    try {
      const localFormState = formState;

      setFormState((prev) => ({
        state: 'loading',
        fieldsCount:
          (prev.state === 'file-upload' || prev.state === 'demo') && prev.selectedFields.length
            ? prev.selectedFields.length
            : FIELDS.length,
      }));

      const response = await prepareRequest(localFormState);

      const responseJson = (await response.json()) as Response;

      if (responseJson.data != null) {
        setFormState({
          state: 'results',
          data: responseJson.data,
        });
      } else if (responseJson.message != null) {
        setFormState({
          state: 'error',
          message: responseJson.message,
        });
      } else if (responseJson.error != null) {
        setFormState({
          state: 'error',
          message: responseJson.error,
        });
      } else {
        setFormState({
          state: 'error',
          message: 'Unknown error',
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        setFormState({
          state: 'error',
          message: e.message,
        });
      } else {
        console.error(e);
        setFormState({
          state: 'error',
          message: 'Unknown error',
        });
      }
    }
  };

  const handleGoBack = () => {
    setFormState({
      state: 'demo',
      documentType: DEFAULT_DOCUMENT,
      selectedFields: [],
    });
  };

  if (formState.state === 'loading') {
    return (
      <main className={styles.root}>
        <FormSection loading scrollIntoView header="Your Extracted Info" subheader="Loading data…">
          <ResultsSkeleton fieldsCount={formState.fieldsCount} />
        </FormSection>
        <IllegalButton className={styles.button} disabled>
          Go back
        </IllegalButton>
      </main>
    );
  }

  if (formState.state === 'results') {
    return (
      <main className={styles.root}>
        <FormSection
          scrollIntoView
          header="Your Extracted Info"
          subheader="This is what we found in your document"
        >
          <Results results={formState.data} />
        </FormSection>
        <IllegalButton className={styles.button} onClick={handleGoBack}>
          Go back
        </IllegalButton>
      </main>
    );
  }

  if (formState.state === 'error') {
    return (
      <main className={styles.root}>
        <FormSection
          scrollIntoView
          header="Error!"
          subheader="Unfortunately, there's been an error with your request. See the details below"
          error
        >
          {formState.message}
        </FormSection>
        <IllegalButton className={styles.button} onClick={handleGoBack}>
          Go back
        </IllegalButton>
      </main>
    );
  }

  return (
    <form className={styles.root} onSubmit={handleSubmit}>
      {(formState.state === 'demo' || formState.state === 'file-upload') && (
        <FormStateSwitch state={formState.state} onUpdateState={handleSwitch} />
      )}
      {formState.state === 'demo' && (
        <FormSection header="Extract information from plain text" subheader="This is NDA demo text">
          <TextContent />
        </FormSection>
      )}
      {formState.state === 'file-upload' && (
        <FormSection header="Upload a file" subheader="Extract information from file">
          <FileUploadControl onFileChange={handleFileChange} />
        </FormSection>
      )}
      {(formState.state === 'file-upload' || formState.state === 'demo') && (
        <>
          <FormSection header="Choose document type" subheader="More documents available soon">
            <TypesSelector selectedType={formState.documentType} />
          </FormSection>
          <FormSection
            header="What should I look for?"
            subheader="Specify the detailed information that you are looking for"
          >
            <FieldsSelector selected={formState.selectedFields} onToggleField={handleFieldChange} />
          </FormSection>
        </>
      )}
      <IllegalButton
        type="submit"
        className={styles.button}
        disabled={formState.state === 'file-upload' && formState.file == null}
      >
        Extract your content
      </IllegalButton>
    </form>
  );
}
